import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import _ from 'xe-utils';
import CustomerList from './customer-list.vue';
import CompanyList from './company_list.vue';

formCreate.component('CustomerList', CustomerList);
formCreate.component('CompanyList', CompanyList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'pre_investment_form',
      formParentCode: 'CRM20210918000002709',
    };
  },

  methods: {
    // 设置rule
    setRule(item) {
      const v = item;

      if (v.field === 'customerName') { // 选择客户
        v.props = {
          ...v.props,
          params: {
            functionCode: 'select_customer',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'orgName') { // 选择组织
        v.props = {
          ...v.props,
          params: {
            functionCode: 'org_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'ext8') { // 选择公司主体
        v.props = {
          ...v.props,
          params: {
            functionCode: 'company_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'ext10') { // 选择职位
        v.props = {
          ...v.props,
          params: {
            functionCode: 'position_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'ext1') {
        v.restful = '/tpm/tpmFiscalYearSettingController/list';
        v.restfulParams = { pageSize: -1, enableStatus: '009' };
        v.props = {
          ...v.props,
          remoteParams: 'yearName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'yearName',
          value: 'year',
        };
      }
      if (v.field === 'budgetSubjectsCode') { // 根据费用类型，查询关联的预算科目
        v.restful = '/tpm/tpmBudgetSubjectsExt/list';
        v.restfulParams = {
          pageSize: -1,
          subjectsTypeCode: 'input',
          enableStatus: '009',
        };
        v.props = {
          ...v.props,
          remoteParams: 'budgetSubjectsName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'budgetSubjectsName',
          value: 'budgetSubjectsCode',
        };
        v.on = {
          change: (e) => {
            if (e) {
              this.setValue({
                budgetSubjectsCode: e,
              });
            } else {
              this.setValue({
                budgetSubjectsCode: null,
                budgetSubjectsName: null,
              });
            }
          },
          getLabel: (e) => {
            this.setValue({
              budgetSubjectsName: e,
            });
          },
        };
      }
      if (v.field === 'ext22') { // 年度任务
        v.on = {
          change: (e) => {
            this.setValue({
              ext22: e,
            });
            this.calcPrice();
          },
        };
      }
      if (v.field === 'ext21') { // 预投入比例
        v.props = {
          ...v.props,
          min: 0,
          precision: 2,
          controls: false,
        };
      }
      if (v.field === 'initAmount') { // 预投入金额（元）
        v.on = {
          change: (e) => {
            this.setValue({
              initAmount: e,
            });
            this.calcPrice();
          },
        };
      }
      return v;
    },
    // 计算价格
    calcPrice() {
      const ext22 = this.getRule('ext22'); // 年度任务
      // const ext21 = this.getRule('ext21'); // 预投入比例
      const initAmount = this.getRule('initAmount'); // 预投入金额（元）
      const target = ext22.value || 0;
      const proportion = initAmount.value || 0;
      // const proportion = ext21.value || 0;
      // const result = _.multiply(target, proportion);
      const result = _.divide(proportion, target);
      this.setValue({
        // initAmount: _.divide(result, 100),
        ext21: _.multiply(result, 100),
      });
    },
    formComplete() {
      const customerName = this.getRule('customerName'); // 客户
      const companyName = this.getRule('ext8'); // 公司

      this.setValue({
        feeBudgetType: 'input',
        feeBudgetTypeName: '市场预投入',
      });

      // 公司主体
      companyName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            ext8: e[0].companyName,
            ext7: e[0].companyCode,
          });
        } else {
          this.setValue({
            ext8: null,
            ext7: null,
          });
        }
      };

      customerName.on.change = (e) => {
        if (e && e.length > 0) {
          this.hiddenFields(true, ['orgName']);
          this.setValue({
            customerName: e[0].customerName,
            customerCode: e[0].customerCode,
          });
        } else {
          this.setValue({
            customerName: null,
            customerCode: null,
          });
        }
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request.get('/tpm/tpmFeeBudgetController/query', {
          id: this.formConfig.row.id,
          feeBudgetType: 'input',
        }).then((res) => {
          if (res.success) {
            this.setValue(res.result);
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/tpm/tpmFeeBudgetExt/save';
        // 有id  就是编辑
        if (this.formConfig.row.id) {
          formData.id = this.formConfig.row.id;
        }
        formData.budgetYear = '2021'; // 标品必传字段，写死
        formData.budgetQuater = '1';
        formData.budgetMonth = '01';
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
